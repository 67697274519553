/** Dependencies **/
import React from 'react';

/** Components **/
import Trend from './../TableTooltips/VolumeTrend';

function Volume( props ) 
{
  // get volume
  let volume = 'NA';
  if( props?.values?.value !== undefined && props.values.value !== null )
    volume = props.values.value;

  // get percent
  let percent = 0;
  if( props?.values?.percent !== undefined && props.values.percent !== null )
    percent = props.values.percent;

  // get variation
  let variation = 0;
  if( props?.values?.variation !== undefined && props.values.variation !== null )
    variation = props.values.variation;

  // get context
  let context = null
  if( props?.context !== undefined )
    context = props.context;

  // set positiv and negativ width for hr design
  const positiveWidth = percent;
  const negativeWidth = 100 - percent;

  return (
    <React.Fragment>
      <div 
        className="volume-container"
        data-tip={'volume-' + props.id} 
        data-for={'volume-' + props.id} 
        data-arrow-color="#e43e21" 
        data-place="bottom"
      >
        { volume !== 'NA' ?
          <div className='hover-area'>&nbsp;</div>
          : null
        } 

        <div className="line-container">
          <hr className={( positiveWidth === 0 ? 'none' : '' ) + ' positive-line'} style={{ width: positiveWidth + "%" }} />          
          <hr className={( negativeWidth === 0 ? 'none' : '' ) + ' negative-line'} style={{ width: negativeWidth + "%" }} />
        </div>

        <p className='value'>{ Number.isInteger( volume ) ? new Intl.NumberFormat('fr-FR').format( volume ) : volume }</p>

        { variation !== 0 ?
          <p className='variation'>{new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 1, style: "decimal",  signDisplay: 'always' }).format( variation )}</p>
          : false
        }

        { volume !== 'NA' ?
          <Trend id={'volume-'+props.id} keywords={props.keywords} context={context} />
          : null
        }
        
      </div>
    </React.Fragment>
  );
}

export default Volume;