import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './../store';

const initialState = {
  value: {}
};

export const loadersSlice = createSlice({
  name: 'loadersTriggers',
  initialState,
  reducers: {
    updateLoadersTriggers: ( state, action ) => 
    {
      // add value into new state
			if( state.value[action.payload] !== undefined )
        state.value[action.payload] = 'trigger-' + Math.floor( Math.random() * 1000 );
      else
        state.value = { [action.payload]: 'trigger-' + Math.floor( Math.random() * 1000 ) };
    }
  },
  extraReducers: builder => builder.addCase( revertAll, () => initialState )
})

export const { updateLoadersTriggers } = loadersSlice.actions

export default loadersSlice.reducer