import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './../store';

const initialState = {
  value: 'volume'
};

export const selectedDataVizTabSlice = createSlice({
  name: 'selectedDataVizTab',
  initialState,
  reducers: {
    updateSelectedDataVizTab: ( state, action ) => 
    {
      state.value = action.payload;
    }
  },
  extraReducers: builder => builder.addCase( revertAll, () => initialState )
})

export const { updateSelectedDataVizTab } = selectedDataVizTabSlice.actions

export default selectedDataVizTabSlice.reducer