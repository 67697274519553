/** Dependencies **/
import React from "react";
import ReactTooltip from 'react-tooltip';

function FiltersRemoveTooltip ( props )
{
  /** Get from props **/
  const filters = props.filters;
  const id = filters.map( filter => filter.props.id.substring(0,1) ).reduce( ( a, b ) => a + b );

  const removeAllFilters = ( e ) => 
  {
    // get current element
    const currentElement = e.currentTarget;

    // get all filters buttons of popin
    const allButtons = currentElement.parentNode.parentNode.querySelectorAll( '.filter-container .filter button' );

    Array.from( allButtons ).map( button => button.click() );
  }

  return (
    <React.Fragment>
      <button 
        className='more-filter'
        data-place='bottom'
        data-for={'filter-' + id} 
        data-tip
        data-event='click'
      >(+{filters.length - 3})</button>
      <ReactTooltip 
        id={'filter-' + id}
        className="tooltip red more-filter-tooltip"
        effect='solid'
        delayHide={100}
        delayShow={100}
        delayUpdate={500}
        globalEventOff='click'
        arrowColor="#e43e21"
        isCapture={true}
        overridePosition={ (
          position,
          currentEvent,
          currentTarget,
          node
        ) => {
          // get width of tooltip
          let width = node.offsetWidth;
          
          return {
            left: currentTarget.offsetLeft - (width / 2),
            top: 20
          };
        }}
      >
      <div>
        <div className="filter-container">
          <div className="filter">
            <div className="title">
              <h4>{filters.length + " Tris & filters actifs :"}</h4>
            </div>
            {filters.map( filter => 
              React.cloneElement( filter )
            )}
          </div>      
        </div>
        <div className="confirm-container">
          <button className="confirm" onClick={ ( e ) => removeAllFilters( e ) }>SUPPRIMER TOUT</button>
          {/* <button className="reset" onClick={ () => ReactTooltip.hide() }>fermer</button> */}
        </div>
      </div>
      </ReactTooltip>
    </React.Fragment>
  )
}

export default FiltersRemoveTooltip;