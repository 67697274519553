import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './../store';

const initialState = {
  value: 'month'
};

export const selectedViewTypeDataViz = createSlice({
  name: 'selectedViewTypeDataViz',
  initialState,
  reducers: {
    updateSelectedViewTypeDataViz: ( state, action ) => 
    {
      state.value = action.payload;
    }
  },
  extraReducers: builder => builder.addCase( revertAll, () => initialState )
})

export const { updateSelectedViewTypeDataViz } = selectedViewTypeDataViz.actions

export default selectedViewTypeDataViz.reducer