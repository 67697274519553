/** Dependencies **/
import React from 'react';

function TogglerButtonOption( props ) 
{
  /** Get props **/
  const togglerState = props.togglerState;
  const callBackTogglerFn = props.callBackTogglerFn;
  const labels = props.labels;
  const values = props.values;

  return (
    <div className='dataviz-option button'>
      {labels.map( ( label, index ) => 
        <button 
          key={index}
          onClick={ () => callBackTogglerFn( values[index] ) } 
          className={togglerState === values[index]?'on':''}
        >{label}</button>
      )}
    </div>
	);
}

export default TogglerButtonOption;