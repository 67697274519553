import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null
}

export const selectedInstanceSlice = createSlice({
  name: 'selectedInstance',
  initialState,
  reducers: {
    updateSelectedInstance: ( state, action ) => 
    {
      state.value = action.payload;
    }
  },
})

export const { updateSelectedInstance } = selectedInstanceSlice.actions

export default selectedInstanceSlice.reducer