import React from 'react';

export const GoogleViewer = ( props ) => (
  <svg width={props.width ? props.width : "28px"} height="25px" viewBox="0 0 28 25" version="1.1">
    <defs>
      <path d="M8.89534884,3.68181818 L4.60465116,3.68181818 L4.60465116,5.42045455 L7.0744186,5.42045455 C6.84418605,6.525 5.88139535,7.15909091 4.60465116,7.15909091 C3.09767442,7.15909091 1.88372093,5.97272727 1.88372093,4.5 C1.88372093,3.02727273 3.09767442,1.84090909 4.60465116,1.84090909 C5.25348837,1.84090909 5.83953488,2.06590909 6.3,2.43409091 L7.63953488,1.125 C6.82325581,0.429545455 5.77674419,0 4.60465116,0 C2.05116279,0 0,2.00454545 0,4.5 C0,6.99545455 2.05116279,9 4.60465116,9 C6.90697674,9 9,7.36363636 9,4.5 C9,4.23409091 8.95813953,3.94772727 8.89534884,3.68181818 Z" id="path-v50iwd5day-1"></path>
      <path d="M8.89534884,3.68181818 L4.60465116,3.68181818 L4.60465116,5.42045455 L7.0744186,5.42045455 C6.84418605,6.525 5.88139535,7.15909091 4.60465116,7.15909091 C3.09767442,7.15909091 1.88372093,5.97272727 1.88372093,4.5 C1.88372093,3.02727273 3.09767442,1.84090909 4.60465116,1.84090909 C5.25348837,1.84090909 5.83953488,2.06590909 6.3,2.43409091 L7.63953488,1.125 C6.82325581,0.429545455 5.77674419,0 4.60465116,0 C2.05116279,0 0,2.00454545 0,4.5 C0,6.99545455 2.05116279,9 4.60465116,9 C6.90697674,9 9,7.36363636 9,4.5 C9,4.23409091 8.95813953,3.94772727 8.89534884,3.68181818 Z" id="path-v50iwd5day-3"></path>
      <path d="M8.89534884,3.68181818 L4.60465116,3.68181818 L4.60465116,5.42045455 L7.0744186,5.42045455 C6.84418605,6.525 5.88139535,7.15909091 4.60465116,7.15909091 C3.09767442,7.15909091 1.88372093,5.97272727 1.88372093,4.5 C1.88372093,3.02727273 3.09767442,1.84090909 4.60465116,1.84090909 C5.25348837,1.84090909 5.83953488,2.06590909 6.3,2.43409091 L7.63953488,1.125 C6.82325581,0.429545455 5.77674419,0 4.60465116,0 C2.05116279,0 0,2.00454545 0,4.5 C0,6.99545455 2.05116279,9 4.60465116,9 C6.90697674,9 9,7.36363636 9,4.5 C9,4.23409091 8.95813953,3.94772727 8.89534884,3.68181818 Z" id="path-v50iwd5day-5"></path>
      <path d="M8.89534884,3.68181818 L4.60465116,3.68181818 L4.60465116,5.42045455 L7.0744186,5.42045455 C6.84418605,6.525 5.88139535,7.15909091 4.60465116,7.15909091 C3.09767442,7.15909091 1.88372093,5.97272727 1.88372093,4.5 C1.88372093,3.02727273 3.09767442,1.84090909 4.60465116,1.84090909 C5.25348837,1.84090909 5.83953488,2.06590909 6.3,2.43409091 L7.63953488,1.125 C6.82325581,0.429545455 5.77674419,0 4.60465116,0 C2.05116279,0 0,2.00454545 0,4.5 C0,6.99545455 2.05116279,9 4.60465116,9 C6.90697674,9 9,7.36363636 9,4.5 C9,4.23409091 8.95813953,3.94772727 8.89534884,3.68181818 Z" id="path-v50iwd5day-7"></path>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Lignes-Copy-15" transform="translate(-696.000000, -8.000000)">
        <g id="SERP" transform="translate(390.000000, 10.000000)">
          <g id="ViewSerp" transform="translate(307.000000, 0.000000)">
            <path d="M2,1 L8.5,1 C13.1944204,1 17,4.80557963 17,9.5 L17,20 C17,21.1045695 16.1045695,22 15,22 L2,22 C0.8954305,22 1.3527075e-16,21.1045695 0,20 L0,3 C-1.3527075e-16,1.8954305 0.8954305,1 2,1 Z" id="Rectangle" stroke="#3A3939" fill="#FFFFFF"></path>
            <g id="20210618182605!Google__G__Logo" transform="translate(2.000000, 3.000000)">
              <g id="Clipped">
                <mask  id={props.id + "-mask-v50iwd5day-2"} fill="white">
                  <use href="#path-v50iwd5day-1"></use>
                </mask>
                <g id="a"></g>
                <polygon id="Path" fill="#FBBC05" fillRule="nonzero" mask={"url(#" + props.id + "-mask-v50iwd5day-2)"} points="-0.418604651 7.15909091 -0.418604651 1.84090909 3.13953488 4.5"></polygon>
              </g>
              <g id="Clipped">
                <mask id={props.id + "-mask-v50iwd5day-4"} fill="white">
                  <use href="#path-v50iwd5day-3"></use>
                </mask>
                <g id="a"></g>
                <polygon id="Path" fill="#EA4335" fillRule="nonzero" mask={"url(#" + props.id + "-mask-v50iwd5day-4)"} points="-0.418604651 1.84090909 3.13953488 4.5 4.60465116 3.25227273 9.62790698 2.45454545 9.62790698 -0.409090909 -0.418604651 -0.409090909"></polygon>
              </g>
              <g id="Clipped">
                <mask id={props.id + "-mask-v50iwd5day-6"} fill="white">
                  <use href="#path-v50iwd5day-5"></use>
                </mask>
                <g id="a"></g>
                <polygon id="Path" fill="#34A853" fillRule="nonzero" mask={"url(#" + props.id + "-mask-v50iwd5day-6)"} points="-0.418604651 7.15909091 5.86046512 2.45454545 7.51395349 2.65909091 9.62790698 -0.409090909 9.62790698 9.40909091 -0.418604651 9.40909091"></polygon>
              </g>
              <g id="Clipped">
                <mask id={props.id + "-mask-v50iwd5day-8"} fill="white">
                  <use href="#path-v50iwd5day-7"></use>
                </mask>
                <g id="a"></g>
                <polygon id="Path" fill="#4285F4" fillRule="nonzero" mask={"url(#" + props.id + "-mask-v50iwd5day-8)"} points="9.62790698 9.40909091 3.13953488 4.5 2.30232558 3.88636364 9.62790698 1.84090909"></polygon>
              </g>
            </g>
            <g id="sort_asc_off-copy-8" transform="translate(3.000000, 14.000000)" stroke="#656565" strokeLinecap="square">
              <line x1="0.375" y1="1" x2="8.625" y2="1" id="Line-4-Copy-3" strokeWidth="2"></line>
              <line x1="5.125" y1="4" x2="7.875" y2="4" id="Line-4-Copy-4"></line>
              <line x1="5.125" y1="6" x2="7.875" y2="6" id="Line-4-Copy-5"></line>
              <line x1="0.125" y1="4" x2="2.875" y2="4" id="Line-4-Copy-7"></line>
              <line x1="0.125" y1="6" x2="2.875" y2="6" id="Line-4-Copy-6"></line>
            </g>
            <g id="noun-redo-arrow-765040" transform="translate(14.000000, 0.000000)" fill="#3A3939" fillRule="nonzero" stroke="#FFFFFF" strokeWidth="2">
              <path d="M6.00179513,-1.83977144 L11.2879468,2.62105531 L7.1909759,7.9555241 L6.31366309,5.54445129 C5.15965248,5.67575712 4.10541833,6.13430271 3.24595742,6.82523513 C2.1984493,7.66734129 1.43955385,8.85401614 1.14226923,10.2136101 L1.14226923,10.2136101 L0.169213081,14.6637605 L-0.811213568,10.2152281 C-0.932781545,9.66363244 -1,9.09175043 -1,8.50190444 C-1,6.29431926 -0.104448054,4.29599213 1.34387416,2.84960813 C2.56976501,1.62535783 4.19173271,0.796529355 6.00179513,0.571252058 L6.00179513,0.571252058 L6.00179513,-1.83977144 Z" id="Path"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>  
);