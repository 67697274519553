import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from './../store';

const initialState = {
  value: {}
};

export const datasSlice = createSlice({
  name: 'datas',
  initialState,
  reducers: {
    updateDatas: ( state, action ) => 
		{
      state.value = { ...state.value, ...action.payload };
    }
  },
  extraReducers: builder => builder.addCase( revertAll, () => initialState )
})

export const { updateDatas } = datasSlice.actions

export default datasSlice.reducer