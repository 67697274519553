/** Dependencies **/
import React, {useState, useContext, useEffect} from 'react';
import Sticky from 'react-sticky-el';
import { login } from './../../../services/AuthApi';
import { useNavigate } from "react-router-dom";

/** Redux **/
import { useDispatch } from 'react-redux';
import { updateEnabledInstances } from './../../../reducers/enabledInstances';

/** Contexts */
import Auth from '../../../contexts/Auth'

/** SCSS **/
import './Login.scss';

function Login() 
{
  /** Init context */
  const { isAuthenticated, setIsAuthenticated } = useContext( Auth );

  /** Istance of history */
  const navigate = useNavigate();

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Init state **/
  const [user, setUser] = useState({
    email: '',
    password: ''
  });
  const [message, setMessage] = useState( null );  
  
  /**
   * Set email and password for user login
   * @param {Event} e 
   */
  const handleChange = ( e ) => 
  {
    setUser( { ...user, [e.target.name]: e.target.value } );
  }

  /**
   * Submit login form
   * @param {Event} e 
   */
  const submitForm = async e => 
  {
    e.preventDefault();

    try
    {
      // login with user datas
      const response = await login( user );
      
      if( response.response === true )
      {
        // set authenticated to true
        setIsAuthenticated( true );        

        // dispatch enabled instances
        dispatch( updateEnabledInstances( response.data.enabled_instances ) );

      } else {

        // set authenticated to false
        setIsAuthenticated( false );        

        // dispatch reset enabled instances
        dispatch( updateEnabledInstances( [] ) );
      }

      // set message
      setMessage( response.message );

    } catch ( error ) {
      setMessage( 'Erreur d\'authentification : ' + error );
    }
  }

  // redirect page to barometer if already connected
  useEffect(() => 
  {
    if( isAuthenticated === true )
      navigate( process.env.PUBLIC_URL + '/keywords' );

  }, [ navigate, isAuthenticated ]);

  return (
    <React.Fragment>
      <Sticky className="sticky-container">
        <div className="top-docker login">
          <div className="top-docker-title">
            <h2>Authentification</h2>
          </div>
        </div>
      </Sticky>
      <div className="dashboard-container login">
        <form onSubmit={submitForm} method="post" id="form-login">
          <div className="row">
            <label htmlFor="email">Email : </label>
            <input 
              type="email" 
              placeholder="Entrer votre email" 
              name="email" 
              id="email" 
              onChange={handleChange} value={user.email} 
            />
          </div>
          <div className="row">
            <label htmlFor="password">Mot de passe : </label>
            <input 
              type="password" 
              placeholder="Entrer votre mot de passe"
              name="password" 
              id="password" 
              onChange={handleChange} 
              value={user.password} 
            />
          </div>
        </form>
        <button type="submit" form="form-login" value="OK">OK</button>
        {message !== null ?
          <div className='message'>{message}</div>
          : false
        }
      </div>
    </React.Fragment>
  )
}
  
export default Login;