/** Dependencies **/
import React from 'react';
import { 
  VictoryArea, 
  VictoryChart, 
  VictoryAxis, 
  VictoryTooltip,
  createContainer
} from 'victory';

import moment from 'moment';
import 'moment/locale/fr';

function PerfsPositionsTrend( props ) 
{
  const VictoryCursorVoronoiContainer = createContainer( "voronoi", "cursor" );

  // Get props
  const {
    datas
  } = props;

	return (
		<React.Fragment>
			<VictoryChart
        width={200}
        height={50}  
        domainPadding={{y: 7}}
        padding={{ top: 20, bottom: 0, left: 5, right: 5 }}
        containerComponent={
          <VictoryCursorVoronoiContainer
            labels={({ datum }) => datum.y === 101 ? 'S' : `${ new Intl.NumberFormat('fr-FR').format( datum.y ) }`}
            labelComponent={
              <VictoryTooltip
                style={{ fontSize: 9 }}
                flyoutStyle={{ strokeWidth: 1 }}
                flyoutWidth={40}
                flyoutHeight={15}
                pointerWidth={4}
                pointerLength={4}
                cornerRadius={1}
                constrainToVisibleArea
              />
            }
            cursorDimension="x"
          />
        }
			>
				<VictoryArea 
          data={datas}
          style={{
            data: {
              fill: 'lightblue',
              stroke: 'teal' 
            }
          }}
        />
        <VictoryAxis 
          tickFormat={ t => `${moment(t).format('MMM')}`.substring(0, 1).toUpperCase() }
          style={{
            ticks: {
              stroke: "grey", 
              size: 9
            },
            tickLabels: {
              fontSize: 9, 
              padding: 0
            }
          }}          
          orientation='top'
        />
        <VictoryAxis dependentAxis
          tickValues={[ datas[datas.length - 1].y ]}
          invertAxis={true}
          offsetX={5}
          style={{
            ticks: {
              stroke: "grey", 
              size: 5
            },
            tickLabels:{
              stroke: 'teal',
              fontSize: 9,
              strokeWidth: 1,
              padding: 2
            }
          }}    
        />        
			</VictoryChart>
    </React.Fragment>
	);
}

export default PerfsPositionsTrend;