/** Dependencies **/
import {useState, useRef, useEffect} from 'react';

function DataVizTooltipVolume( props ) 
{
  /** Get props **/
  const { x, activePoints } = props;
  
  /** Init state **/
  const [tooltipOffsetX, setTooltipOffsetX] = useState( 0 );

  /** Set ref **/
  const tooltipContainer = useRef( null );

  /** Set datas for tooltip **/
  let datas = [];
  activePoints.forEach( point => {
    datas = [...datas, {
      value: point.y,
      color: point.style.data.fill,
      tickValue: point.tickValue,
      labelValue: point.labelValue
    }];
  });

  /** Set difference between current and compare values **/
  let variation = null;
  if( 
    datas[0] 
    && datas[0].value >= 0
    && datas[1] 
    && datas[1].value >= 0
  )
    variation = datas[0].value - datas[1].value;

  /** Update x position for tooltip **/
  useEffect( () => 
  {
    if( tooltipContainer !== null )
    {
      const tooltipWidth = tooltipContainer.current.offsetWidth;
      if( x > 1350 / 2 )
        setTooltipOffsetX( - tooltipWidth - 10 );
      else
        setTooltipOffsetX( 10 );
    }
  }, []);

  return (
    <foreignObject x={x + tooltipOffsetX} y={40} width="350" height="75">
      <div className="tooltip grey" ref={tooltipContainer} style={tooltipContainer.current === null ? {visibility: 'hidden'} : {}}>
        <div className="tooltip-container">
          {datas[0] ?
            <ul>
              <li>{datas[0].tickValue}</li>
              <li>
                <hr style={{backgroundColor: datas[0].color}} />
                {new Intl.NumberFormat( 'fr-FR' ).format( datas[0].value )}
              </li>
            </ul>
            : false
          }
          {datas[1] ?
            <ul>
              <li>{datas[1].tickValue}</li>
              <li>
                <hr className='dash' style={{backgroundColor: datas[1].color}} />
                {new Intl.NumberFormat( 'fr-FR' ).format( datas[1].value )}
              </li>
            </ul>
            : false
          }
          {variation !== null ?
            <ul>
              <li>VARIATION</li>
              <li>
                <span className={ variation >= 0 ? "arrow up" : "arrow down" }>&#8682;</span>
                {new Intl.NumberFormat( 'fr-FR', { signDisplay: 'always' } ).format( variation )}
              </li>
            </ul>
            : false
          }
        </div>
      </div>
    </foreignObject>
  );
};

export default DataVizTooltipVolume;