import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './../store';

const initialState = {
  value: { 
    category1: [], 
    category2: [], 
    category3: []
  }
};

export const listCategoryDispSlice = createSlice({
  name: 'listCategoryDisp',
  initialState,
  reducers: {
    updateListCategoryDisp: ( state, action ) => 
    {
      state.value = action.payload;
    }
  },
  extraReducers: builder => builder.addCase( revertAll, () => initialState )
})

export const { updateListCategoryDisp } = listCategoryDispSlice.actions

export default listCategoryDispSlice.reducer