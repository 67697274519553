/** Dependencies **/
import React from 'react';

function KDifficulty( props ) 
{
  // get value of keyword difficulty
  let kdifficulty = null;
  if( props.values && props.values.value )
    kdifficulty = props.values.value;

  // get percent of keyword difficulty
  let percent = null;
  if( props.values && props.values.percent )
    percent = props.values.percent;

  // set positiv and negativ width for hr design
  const positiveWidth = percent;
  const negativeWidth = 100 - percent;

  return (
    <div className="kdifficulty-container">
      { percent !== null ?
        <div className="line-container">
          <hr className={( positiveWidth === 0 ? 'none' : '' ) + ' positive-line'} style={{ width: positiveWidth + "%" }} />          
          <hr className={( negativeWidth === 0 ? 'none' : '' ) + ' negative-line'} style={{ width: negativeWidth + "%" }} />
        </div>
        : false
      }
      { kdifficulty !== null ?
        <p className='value'>
          {new Intl.NumberFormat('fr-FR').format( kdifficulty )}%
        </p>
        : false
      } 
    </div>
  );
}

export default KDifficulty;