import React from 'react';
import Sticky from 'react-sticky-el'

function Home() {

  return (
    <React.Fragment>
      <Sticky className="sticky-container">
        <div className="top-docker">
          <div className="top-docker-left">
            <h2>Bienvenue dans votre nouvel outil Observatoire</h2>
          </div>
        </div>
      </Sticky>
    </React.Fragment>
  )
}
  
export default Home;