/** Dependencies **/
import React from "react";

/** Helpers **/
import { cleanString } from './functions';

/**
 * Return suggestion values to set auto suggestion field
 * @param {Array} suggestion 
 * @returns 
 */
export const getSuggestionValue = suggestion => suggestion;

/**
 * Set suggestion array after apply custom filter for string
 * @param {Object} 
 */
export const onSuggestionsFetchRequested = ( { value }, fetchRequestedFct, callBackFct ) =>
{
  let suggestions = [];

  if( value !== '' )
    suggestions = fetchRequestedFct( value );

  callBackFct( suggestions.sort() );
}

/**
 * Render suggestion menu
 * @param {Array} suggestion 
 * @returns 
 */
 export const renderSuggestion = ( suggestion, inputValue ) => 
 {
  let result = '';

  if( suggestion !== '' )
  {
    let splitValues = [];
    let matches = [];
    try 
    {
      // init regExp with and without accent
      const regExp = new RegExp( cleanString( inputValue ) + '|' + inputValue, 'gi' );

      // exec regexp on suggestion word
      matches = [...cleanString( suggestion ).matchAll( regExp )];

      // split suggestion word with regExp
      splitValues = cleanString( suggestion ).split( regExp );
      
    } catch (error) {
      console.warn( error );
    }

    // init split result array for display content
    let splitResult = [];

    if( splitValues.length > 0 )
    { 
      splitValues.forEach( (elem, index, array) => 
      { 
        // first value id highlight
        if( index === 0 && elem === '' && matches[index] )
          splitResult.push( <span key={index} className="highlight">{suggestion.slice( matches[index].index, matches[index].index + matches[index][0].length )}</span> );
        // value is in middle of suggestion
        else if( index < array.length - 1 && matches[index] ){
          splitResult.push( suggestion.slice( matches[index].index - elem.length, matches[index].index ) );
          splitResult.push( <span key={index} className="highlight">{suggestion.slice( matches[index].index, matches[index].index + matches[index][0].length )}</span> );
        // last value is not inputValue
        } else if( index === array.length - 1 && elem !== '' ){
          splitResult.push( suggestion.slice( -elem.length ) );
        }
      });
    }

    if( splitResult.length > 0 )
    { 
      let concatResult = splitResult.reduce( ( prev, curr ) => [prev, curr] );

      // add span with bidi class for url wich finished with / to set with bidi-override value
      if( 
        concatResult instanceof Array
        && concatResult[concatResult.length - 1] 
        && typeof concatResult[concatResult.length - 1] === 'string'
        && concatResult[concatResult.length - 1].slice( -1 ) === '/' 
      ){
        concatResult[concatResult.length - 1] = <React.Fragment>
          {concatResult[concatResult.length - 1].slice( 0, -1 )}<span className="bidi-override">/</span>
        </React.Fragment>
      }

      result = <React.Fragment>{concatResult}</React.Fragment>;

    }
  }
  
  return result;
 }