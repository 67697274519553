import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './../store';

const initialState = {
  value: 1
};

export const levelCategorySlice = createSlice({
  name: 'levelCategory',
  initialState,
  reducers: {
    updateLevelCategory: ( state, action ) => 
    {
      state.value = action.payload;
    }
  },
  extraReducers: builder => builder.addCase( revertAll, () => initialState )
})

export const { updateLevelCategory } = levelCategorySlice.actions

export default levelCategorySlice.reducer