/** Dependencies **/
import {useRef} from 'react';

function TogglerCheckboxOption( props ) 
{
  /** Get props **/
  const {
    id,
    groupID,
    togglerState,
    callBackTogglerFn,
    label,
    borderColor,
    backgroundColor
  } = props;  
  
  /** Set Refs **/
  const inputCheckbox = useRef( null );

  /** Set classname of label **/
  let labelClassName = '';
  if( togglerState === true )
    labelClassName = 'on';
  else if ( togglerState === 'disable' )
    labelClassName = 'disable';

  return (
    <div className="dataviz-option checkbox">
      <div>
        <input 
          ref={inputCheckbox}
          type='checkbox' 
          onChange={ e => callBackTogglerFn( e.currentTarget.checked, e.currentTarget ) }
          name='checkbox-toggler'
          id={id}
          groupid={groupID}
          checked={!togglerState}
          disabled={togglerState === 'disable' ? 'disabled' : ''}
        />
        <label 
          style={{
            '--borderColor': borderColor,
            '--backgroundColor': backgroundColor
          }}
          className={labelClassName}
          htmlFor={id}
        >{label}</label>
      </div>
      <button 
        className='only' 
        value={label} 
        onClick={ () => callBackTogglerFn( true, inputCheckbox.current, true ) }
      >seulement</button>
    </div>
	);
}

export default TogglerCheckboxOption;