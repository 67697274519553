/** Dependencies **/
import moment from 'moment';

/**
 * Return value of field wich could be automatic or updated
 * @param {Object} datas 
 * @param {String} fieldValue 
 * @returns String
 */
export const getUpdateOrAutoValue = ( datas, fieldValue ) => 
{
  const fieldName = fieldValue.charAt( 0 ).toUpperCase() + fieldValue.slice( 1 );
  const updateFieldName = 'updated' + fieldName;
  const autoFieldName = 'automatic' + fieldName;
  
  return (
    datas !== undefined
    && datas[updateFieldName] !== undefined 
    && datas[updateFieldName] !== null ?
    datas[updateFieldName]
    : 
    datas !== undefined
    && datas[autoFieldName] !== undefined 
    && datas[autoFieldName] !== null ?
    datas[autoFieldName]
    : null
  );
}

/**
 * 
 * @param {string} typePeriod 'currentPeriod' or 'comparePeriod'
 * @returns default value for current and compare period
 */
export const getInitDateRange = ( typePeriod ) =>
{
  let result = null;

  switch (typePeriod) {
    case 'currentPeriod':
      result = {
        startdate: moment().subtract( 1, 'years' ).add( 1, 'month' ).startOf( 'month' ).format( 'YYYYMMDD' ),
        enddate: moment().endOf( 'month' ).format( 'YYYYMMDD' )
      };
      break;
    case 'comparePeriod':
      result = {
        startdate: moment().subtract( 2, 'years' ).add( 1, 'month' ).startOf( 'month' ).format( 'YYYYMMDD' ),
        enddate: moment().subtract( 1, 'years' ).endOf('month').format( 'YYYYMMDD' )
      };
      break;
    default:
      break;
  }

  return result;
}

/**
 * Return true if action is "A ignorer" => id=3
 * @returns {Boolean}
 */
export const getIsIgnore = ( item ) => 
{
  let result = false;
  
  // set ignore action ID to 3
  const ignoreActionID = 3;
  
  if( item?.action !== undefined )
  {
    result = ( 
      item.action?.updatedActionID !== undefined 
      && item.action.updatedActionID === parseInt( ignoreActionID )
     ) ?
      true
    : (
      item.action?.automaticExpectedUrl !== undefined
      && item.action.automaticExpectedUrl === parseInt( ignoreActionID )
    ) ?
      true
    : false;
  }

  return result;
}

/**
 * Return int with last snippets update date
 * @param {JSON} datas 
 * @returns 
 */
export const getLastSnippetsUpdateDate = ( datas ) =>
{
  let lastDate = null;  

  if( datas.currentSERPTrend && datas.currentSERPTrend.detailGrpSnippets )
    lastDate = Math.max( 
      ...Object.keys( datas.currentSERPTrend.detailGrpSnippets ).filter( date => 
        Object.keys( datas.currentSERPTrend.detailGrpSnippets[date] ).filter( type => 
          datas.currentSERPTrend.detailGrpSnippets[date][type].snippets.filter( snippet => snippet.percent > 0 ).length > 0
        ).length > 0
      ).map( value => 
        parseInt( value ) 
      ) 
    );
    
  return lastDate;
}

/**
 * Return int with last snippets update date
 * @param {JSON} datas 
 * @returns 
 */
export const getScrapDate = ( datas, type ) =>
{
  let result = null;

  let fieldName = 'firstScrapDate';  
  if( type === 'last' )
    fieldName = 'lastScrapDate';  

  if( 
    datas?.currentSERPTrend !== undefined 
    && datas.currentSERPTrend[fieldName]
  )
    result = datas.currentSERPTrend[fieldName];
    
  return result;
}