import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './../store';

const initialState = {
  value: {}
};

export const togglerSlice = createSlice({
  name: 'toggler',
  initialState,
  reducers: {
    onToggler: ( state, action ) => 
    {
      state.value[action.payload] = true;
    },
		offToggler: ( state, action ) => 
    {
      state.value[action.payload] = false;
    }
  },
  extraReducers: builder => builder.addCase( revertAll, () => initialState )
})

export const { onToggler, offToggler } = togglerSlice.actions

export default togglerSlice.reducer