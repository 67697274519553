import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: []
};

export const enabledInstancesSlice = createSlice({
  name: 'enabledInstances',
  initialState,
  reducers: {
    updateEnabledInstances: ( state, action ) => 
    {
      state.value = action.payload;
    }
  },
})

export const { updateEnabledInstances } = enabledInstancesSlice.actions

export default enabledInstancesSlice.reducer