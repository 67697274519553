/** Redux **/
import { useDispatch, useSelector } from 'react-redux';

function TogglerRadioOption( props ) 
{
  /** Get props **/
  const {
    id,
    callBackTogglerFn,
    label
  } = props; 

  /** Get state from redux store **/
  const togglers = useSelector( state => state.togglers.value );

  return (
    <div className='dataviz-option radio'>
      <label htmlFor={id}>{label}</label>
      <button 
        onClick={ e => callBackTogglerFn( e.currentTarget ) } 
        className={ 'toggler' + ( togglers[id] ? ' on' : '' ) }
        name={id}
        id={id}
      ><span>&nbsp;</span></button>
    </div>
	);
}

export default TogglerRadioOption;