/** Dependencies **/
import { useContext } from "react";
import { Navigate } from "react-router-dom";

/** Contexts */
import Auth from "../../contexts/Auth";

const AuthenticatedRoute = ({ element }) => 
{
  const { isAuthenticated } = useContext( Auth );

  return isAuthenticated ? (
    element
  ) : (
    <Navigate to={ process.env.PUBLIC_URL + "/login" } />
  )
}

export default AuthenticatedRoute;