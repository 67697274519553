import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './../store';

const initialState = {
  value: {}
};

export const selectedRowIDSlice = createSlice({
  name: 'selectedRowID',
  initialState,
  reducers: {
    updateSelectedRowsID: ( state, action ) => 
    {
      state.value = action.payload;
    }
  },
  extraReducers: builder => builder.addCase( revertAll, () => initialState )
})

export const { updateSelectedRowsID } = selectedRowIDSlice.actions

export default selectedRowIDSlice.reducer