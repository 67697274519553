/** Dependencies **/
import React from 'react';
import { 
  VictoryArea, 
  VictoryChart, 
  VictoryAxis, 
  VictoryTooltip, 
  createContainer
} from 'victory';

import moment from 'moment';
import 'moment/locale/fr';

function VolumeTrend( props ) 
{
  const VictoryCursorVoronoiContainer = createContainer( "voronoi", "cursor" );

  // Get props
  const {
    datas
  } = props;

	return (
		<React.Fragment>
			<VictoryChart
        width={250}
        height={80}        
        domainPadding={{ y: 7 }}
        padding={{ top: 0, bottom: 20, left: 5, right: 5 }}
        containerComponent={
          <VictoryCursorVoronoiContainer
            labels={ ({ datum }) => `${new Intl.NumberFormat('fr-FR').format(datum.y)}` }
            labelComponent={
              <VictoryTooltip
                style={{ fontSize: 9 }}
                flyoutStyle={{ strokeWidth: 1 }}
                flyoutWidth={40}
                flyoutHeight={15}
                pointerWidth={4}
                pointerLength={4}
                cornerRadius={1}
                constrainToVisibleArea
              />
            }
            cursorDimension="x"
          />
        }
			>
				<VictoryArea 
          data={datas.map( data => ({
            x: data.x.toString(),
            y: data.y,
          }))}
          style={{
            data: {
              fill: 'lightblue', 
              stroke: 'teal' 
            }
          }}
        />
        <VictoryAxis 
          tickFormat={ t => `${moment(t).format('MMM')}`.substring(0, 1).toUpperCase() }
          style={{
            ticks: {
              stroke: "grey", 
              size: 9
            },
            tickLabels: {
              fontSize: 9, 
              padding: 0
            }
          }}
        />
			</VictoryChart>
    </React.Fragment>
	);
}

export default VolumeTrend;