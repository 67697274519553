import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './../store';

const initialState = {
  value: {}
};

export const selectedFiltersSlice = createSlice({
  name: 'selectedfilters',
  initialState,
  reducers: {
    updateSelectedFilters: ( state, action ) => 
    {
			if( action.payload.value === null )
				delete state.value[action.payload.filterName];
			else
        state.value = {
					...state.value, 
					[action.payload.filterName]: action.payload.value
				};
    }
  },
  extraReducers: builder => builder.addCase( revertAll, () => initialState )
})

export const { updateSelectedFilters } = selectedFiltersSlice.actions

export default selectedFiltersSlice.reducer