import React from 'react';
import { VictoryPie } from 'victory';

function DataVizPie( props ) {

	const datas = props.datas;

  return (
    <div>
      <VictoryPie
        data={datas}
        width={128}
        height={128}
        padding={{top:5, bottom:20}}
        colorScale={["#e43e21", "transparent"]}
        style={{
          data: {
            stroke: "#e43e21", strokeWidth: 3
          },
          labels: { display:"none" }
        }}        
      />
    </div>
  );
}

export default DataVizPie;