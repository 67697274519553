import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './../store';

const initialState = {
	value: {}
};

export const selectedHeaderFiltersSlice = createSlice({
  name: 'selectedHeaderFilters',
  initialState,
  reducers: {
    updateSelectedHeaderFilters: ( state, action ) => 
    {
			/** REMOVE all filters in column **/
			if( action.payload.filterName === null && action.payload.value === null )
			{
				state.value = {};

			/** REMOVE single filter in column -> action.payload.value **/
			} else if( action.payload.value === null ) {

				delete state.value[action.payload.filterName];

			/** REMOVE multiple filter in column -> action.payload.value.value **/
			} else if( action.payload.value.value === null ) {

				if( state.value[action.payload.filterName] )
					state.value[action.payload.filterName] = state.value[action.payload.filterName].filter( 
						state => state.value.field !== action.payload.value.field 
					);	

				if( state.value[action.payload.filterName].length === 0 )
					delete state.value[action.payload.filterName];

			/** ADD OR UPDATE value **/
			} else {
				
				// if field exist -> multiple filter in column 
				if( action.payload.value.value.field ){

					// Remove value with field if exist
					if( state.value[action.payload.filterName] )
						state.value[action.payload.filterName] = state.value[action.payload.filterName].filter( 
							state => state.value.field !== action.payload.value.value.field
						);
					
					// Add new value in state
					if( state.value[action.payload.filterName] instanceof Array )
						state.value[action.payload.filterName].push( action.payload.value );
					else
						state.value[action.payload.filterName] = [action.payload.value];

				// single filter in column
				} else {
					state.value = {
						...state.value, 
						[action.payload.filterName]: action.payload.value
					};
				}
			}
    }
  },
  extraReducers: builder => builder.addCase( revertAll, () => initialState )
})

export const { updateSelectedHeaderFilters } = selectedHeaderFiltersSlice.actions

export default selectedHeaderFiltersSlice.reducer