import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './../store';

const initialState = {
  value: []
};

export const hiddenColumnsSlice = createSlice({
  name: 'hiddenColumns',
  initialState,
  reducers: {
    loadHiddenColumns: ( state, action ) => 
    {
      state.value = action.payload;
    },
		addHiddenColumns: ( state, action ) => 
    {
			// get values which are not in already into hidden column array
			const newHiddenColumns = action.payload.filter( column => !( state.value.includes( column ) ) );	
		
			// add new values into new state			
      state.value = [...state.value, ...newHiddenColumns];
    },
		removeHiddenColumns: ( state, action ) => 
    {
			// remove value from new state
			const newHiddenColumns = state.value.filter( hiddenColumn => !( action.payload.includes( hiddenColumn ) ));

      // update new values into new state			
      state.value = newHiddenColumns;
    }
  },
  extraReducers: builder => builder.addCase( revertAll, () => initialState )
})

export const { loadHiddenColumns, addHiddenColumns, removeHiddenColumns } = hiddenColumnsSlice.actions

export default hiddenColumnsSlice.reducer