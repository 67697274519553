/** Dependencies **/
import {useState, useRef, useEffect} from 'react';

function DataVizTooltipGSC( props ) 
{
  /** Get props **/
  const { x, activePoints } = props;

  /** Init state **/
  const [tooltipOffsetX, setTooltipOffsetX] = useState( 0 );

  /** Set ref **/
  const tooltipContainer = useRef( null );

  /** Set datas for tooltip **/
  let currentDatas = [];
  let compareDatas = [];
  activePoints.forEach( point => 
  {
    // set object datas
    let datas = {
      value: point.y,
      color: point.style.data.stroke,
      dash: point.style.data.strokeDasharray,
      tickValue: point.tickValue,
      labelValue: point.labelValue
    }
    
    // append object data
    if( point.style.data.strokeDasharray !== undefined )
      compareDatas = [...compareDatas, datas ];
    else
      currentDatas = [...currentDatas, datas ];
  });

  /** Set variation array values **/
  let variationDatas = [];
  if( compareDatas.length > 0 )
    currentDatas.forEach( ( data, index ) => 
    { 
      if( data.value >= 0 )
        variationDatas = [...variationDatas, {
          value: data.labelValue === 'position' ? 
            compareDatas[index].value - data.value 
            : data.value - compareDatas[index].value, 
          color: data.color,
          label: data.labelValue
        }];
    })

  /** Update x position for tooltip **/
  useEffect( () => 
  {
    if( tooltipContainer !== null )
    {
      const tooltipWidth = tooltipContainer.current.offsetWidth;
      if( x > 1350 / 2 )
        setTooltipOffsetX( - tooltipWidth - 10 );
      else
        setTooltipOffsetX( 10 );
    }
  }, []);

  return (
    <foreignObject x={x + tooltipOffsetX} y={40} width="540" height="200">
      <div className="tooltip grey" ref={tooltipContainer} style={tooltipContainer.current === null ? {visibility: 'hidden'} : {}}>
        <div className="tooltip-container">
          {currentDatas.length > 0 ?
            <ul>
              <li>{currentDatas[0].tickValue}</li>
              {currentDatas.map( ( point, index ) => 
                <li key={index}>
                  <hr className={point.dash !== undefined ? 'dash':''} style={{backgroundColor: point.color}} />
                  <span className="label">{point.labelValue}</span>&nbsp;:&nbsp;{new Intl.NumberFormat( 'fr-FR' ).format( point.value )}{point.labelValue === 'ctr' ? ' %' : ''}
                </li>
              )}
            </ul>
            : false
          }
          {compareDatas.length > 0 ?
            <ul>
              <li>{compareDatas[0].tickValue}</li>
              {compareDatas.map( ( point, index ) => 
                <li key={index}>
                  <hr className={point.dash !== undefined ? 'dash':''} style={{backgroundColor: point.color}} />
                  <span className="label">{point.labelValue}</span>&nbsp;:&nbsp;{new Intl.NumberFormat( 'fr-FR' ).format( point.value )}{point.labelValue === 'ctr' ? ' %' : ''}
                </li>
              )}
            </ul>
            : false
          }
          {variationDatas.length > 0 ?
            <ul>
              <li>VARIATION</li>
              {variationDatas.map( ( data, index ) => 
                <li key={index}>
                  <span className="label">{data.label}</span>
                  &nbsp;:&nbsp;
                  <span className={data.value >= 0 ? "arrow up" : "arrow down" }>&#8682;</span>
                  {new Intl.NumberFormat( 'fr-FR', { signDisplay: 'always' } ).format( data.value )}
                </li>
              )}
            </ul>
            : false
          }
        </div>
      </div>
    </foreignObject>
  );
};

export default DataVizTooltipGSC;