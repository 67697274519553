import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './../store';

const initialState = {
  value: []
};

export const filtersDatasSlice = createSlice({
  name: 'filtersDatas',
  initialState,
  reducers: {
    updateFiltersDatas: ( state, action ) => 
    {
      state.value = action.payload;
    }
  },
  extraReducers: builder => builder.addCase( revertAll, () => initialState )
})

export const { updateFiltersDatas } = filtersDatasSlice.actions

export default filtersDatasSlice.reducer