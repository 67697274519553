/** Dependencies **/
import { 
  VictoryLabel,
  VictoryTooltip
} from 'victory';

/** Components **/
import DataVizTooltipPerfPos from './../DataVizTooltips/DataVizTooltipPerfPos';

function CustomLabelPerfPos( props ) 
{
  /** Get props **/
  const {
    compareDisplay, 
    comparePerfTrend,
    currentPerfTrend
  } = props;  

  /** Set array with compared date VS current date **/
  let matchesDates = {};
  if( comparePerfTrend.detailGrpDomains )
    Object.keys(currentPerfTrend.detailGrpDomains).forEach( ( currentDate, index ) => {
      matchesDates[currentDate] = parseInt( Object.keys(comparePerfTrend.detailGrpDomains)[index] )
    });

  return (
    <g>
      <VictoryLabel
        {...props}
        y={0}
        dx={ ( {datum} ) => 
          // set offset to label if match compared date value = 0
          (
            compareDisplay === true 
            && comparePerfTrend.grpDomains
            && Object.keys(comparePerfTrend.grpDomains).filter( grp => 
              comparePerfTrend.grpDomains[grp].filter( elem => 
                elem.x === matchesDates[datum.date] && elem.y > 0 
              ).length > 0
            ).length > 0
          ) ? -12
          : 0
        }
        verticalAnchor={"middle"}
        style={[
          { 
            fill: "white", 
            fontSize: 8, 
          }
        ]}
      />
      <VictoryTooltip
        {...props}
        text={() => ''}
        flyoutComponent={<DataVizTooltipPerfPos {...props} />}
      />
    </g>
  );
};

CustomLabelPerfPos.defaultEvents = VictoryTooltip.defaultEvents;

export default CustomLabelPerfPos;