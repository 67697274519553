/** Dependencies **/
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

/**
 * Return current date with format
 * @param {string} format 
 * @returns int
 */
export const getCurrentDate = ( format ) => 
{
  return parseInt( moment().format( format ) );
}

/**
 * Create XLSX file from data table
 * @param {array} datas array of objects
 */
export const exportXLSX = ( fileName, datas ) => 
{
  // instance workbook
  var wb = XLSX.utils.book_new();

  // push a new worsheet which name Batometer
  wb.SheetNames.push( "Export" );

  // convert json to sheet
  var ws = XLSX.utils.json_to_sheet( 
    datas, 
    { header: Object.keys( datas[0] ) }
  );

  // set worksheet with new datas
  wb.Sheets[ "Export" ] = ws;

  // export to XLSX file format
  XLSX.writeFile( wb, fileName );
}

/**
 * Create PDF from datas and download it
 * @param {array} datas 
 */
export const exportPDF = ( datas ) => 
{
  const doc = new jsPDF( 'l', 'mm', 'a4' );
  let header = [];
  let body = [];

  if( datas.length > 0 )
  {
    // get header
    header = Object.keys( datas[0] );

    datas.forEach( element => {
      body.push( Object.values( element ) );
    });
  }
  
  // set table options and datas
  doc.autoTable({
    head: [header],
    body: body,
    styles: {
      fontSize: 8
    }
  });

  // download tableDatas.pdf file
  doc.save( 'tableDatas.pdf' );
}

/**
 * Normalize / delete accent / lower case
 * @param {string} value 
 * @returns string
 */
export const cleanString = ( value ) => 
{
  return value.toString().normalize( 'NFD' ).replace( /[\u0300-\u036f]/g, '' ).toLowerCase();
}

/**
 * Return domain of url
 * @param {string} url 
 * @returns string
 */
export const getDomain = ( url ) => 
{
  // init result value for return
  let result = url;

  // init regexp
  const regExp = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;

  // apply regexp
  let matchValue = url.match( regExp );
  if( matchValue && matchValue[0] )
    result = matchValue[0];

  return result;
}

/**
 * Return domain of url
 * @param {string} url 
 * @returns string
 */
 export const removeDomain = ( url ) => 
 {
   // init result value for return
   let result = url;
 
   // init regexp
  const regExp = /https?\:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9](\/.*$)/;

  // apply regexp
  let matchValue = url.match( regExp );
  if( matchValue && matchValue[1] )
    result = matchValue[1];
 
   return result;
 }

/**
 * Return truncate string with maxLetter and concat suffix value
 * @param {string} string 
 * @param {int} maxLetter 
 * @param {string} suffix 
 * @returns string
 */
export const truncateString = ( string, maxLetter, suffix ) => 
{
  let result = string;  

  if( 
    typeof string === 'string' 
    && string !== '' 
    && typeof maxLetter === 'number'
    && maxLetter > 0 
    && typeof suffix === 'string'
    && string.length > maxLetter
  )
    result = string.substring( 0, maxLetter ) + suffix;

  return result;
}

/**
 * Format integer to add k for values superior than 1000
 * @param {int} num 
 * @returns 
 */
export const kFormatter = ( num ) => 
{
  const result = (Math.abs(num) > 999) ? 
    new Intl.NumberFormat( 'fr-FR' ).format( Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) ) + 'k' 
    : Math.sign(num)*Math.abs(num)
  ;

  return result;
}

/**
 * Sort object keys order by pattern array values
 * @param {object} object 
 * @param {array} pattern 
 * @returns object
 */
export const orderObjectKeysWithPattern = ( object, pattern ) => 
{
  let result = {};
  pattern.forEach( key => {
    if( object[key] )
      result[key] = object[key]
  });

  return result;
}

/**
 * Toggle classname of the HTMLelement
 * @param {HTMLElement} element 
 * @param {String} className 
 * @returns 
 */
export const togglerClassName = ( element, className ) =>
{
  if( element instanceof HTMLElement )
    element.classList.toggle( className );

  return element;
}

/**
 * Fetch request
 * @param {object} request 
 * @returns 
 */
export const load = async ( request ) => 
{
  // call request url
  const response = await fetch( request.url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify( request.data )
  } );

  // return response
  const result = await response.json();

  return result;
}

/**
 * Return JSON or false if valid or not
 * @param {String} json 
 * @returns Boolean
 */
export const isValidJson = ( json ) => 
{
  try {
    return JSON.parse( json );
  } catch ( e ) {
    return false;
  }
}

/**
 * Return difference between current and compare values with decimal/always sign display
 * @param {float} currentValue 
 * @param {float} compareValue 
 * @returns {float}
 */
export const formatCompareValues = ( currentValue, compareValue ) => 
{
  return new Intl.NumberFormat('fr-FR', { 
    maximumFractionDigits: 1, 
    style: "decimal", 
    signDisplay: 'always' 
  }).format( currentValue - compareValue );
}

/**
 * Return value of string path with object
 * @param {Object} obj {gsc:{impression:value, ...}}
 * @param {String} path 'gsc.impression.value
 * @returns value from object with object path
 */
export const stringToObjectPath = ( obj, path ) =>
{
  // init return object with obj
  let current = obj;

  // split path on dot
  const paths = path.split('.');

  for ( let i = 0 ; i < paths.length ; ++i ) 
  {
    if (current[paths[i]] === undefined)
      return undefined;
    else
      current = current[paths[i]];
  }

  return current;
}