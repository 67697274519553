/** Dependencies **/
import { addItem, getItem, removeItem } from "./LocaleStorage";

/** Helpers */
import { load } from "../helpers/functions";

/**
 * Check if local storage for connection is valid
 * @returns boolean
 */
export const hasAuthenticated = () => 
{
  if( 
    getItem( 'token' ) !== null 
    && getItem( 'email' ) !== null
  ){
    // build request url
    const request = { 
      url: process.env.REACT_APP_WEBSERVICE_URL + '/' + getItem( 'email' ) + '/check-login/', 
      data:{
        token: getItem( 'token' ),
        email: getItem( 'email' )
      }, 
      method:'POST'
    };
  
    // load request
    return load( request ).then( 
      results => results,
      error => {
        throw new Error( error );
      }
    )
  } else
    return { response: false };
}

/**
 * Load check script for connection
 * @param {object} credentials 
 * @returns object with message, response if connection true, datas of user
 */
export const login = ( credentials ) => 
{
  // build request url
  const request = { 
    url: process.env.REACT_APP_WEBSERVICE_URL + '/' + credentials.email + '/check-login/', 
    data:{
      password: credentials.password
    }, 
    method:'POST'
  };

  // load request
  return load( request ).then( 
    results => 
    {
      if( results['response'] && results['response'] === true )
      {
        // set local storage
        addItem( 'email', credentials.email );
        addItem( 'userID', results['data']['id'] );
        addItem( 'firstName', results['data']['first_name'] );
        addItem( 'lastName', results['data']['last_name'] );
        addItem( 'token', results['data']['token'] );
        
      } else
        results['message'] = 'Email ou mot de passe invalide.';

      return results;
    },
    error => {
      throw new Error( error );
    }
  )
}

/**
 * Remove all items from localstorage about login
 */
export const logout = () => 
{
  removeItem( 'email' );
  removeItem( 'userID' );
  removeItem( 'firstName' );
  removeItem( 'lastName' );
  removeItem( 'token' );
}