/** Dependencies **/
import React, { useEffect, useState } from 'react';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedInstance } from '../../reducers/selectedInstance';

/** Components **/
import Select from 'react-select';

/** Helpers **/
import { getStylesSelectMenu } from './../../helpers/ui'

/** SCSS **/
import './HeaderNavAccounts.scss';

function HeaderNavAccounts() 
{
	/** Instance dispatch object **/
	const dispatch = useDispatch();

	/** Get state from redux store **/
	const enabledInstances = useSelector( state => state.enabledInstances.value );
	const selectedInstance = useSelector( state => state.selectedInstance.value );

	/** Init state **/
  const [optionsAccounts, setOptionsAccounts] = useState( [] );

	useEffect( () => 
	{
		if( 
			enabledInstances instanceof Array 
			&& enabledInstances.length > 0
		){
			// sort instances with label
			const sortedEnabledInstances = [...enabledInstances].sort( ( a, b ) => a.label.localeCompare( b.label ) );

			// add all instances to optionsAccount array
			let options = [];
			sortedEnabledInstances.forEach( ( elem, index ) => 
			{
				// push element
				options.push({
					value: elem.nickname,
					label: elem.label
				});
			});

			// set options accounts
			setOptionsAccounts( options );

			// set default selected instance
			dispatch( updateSelectedInstance( options[0].value ) );
		}

	}, [enabledInstances] );

	return (
		optionsAccounts.length > 0 ?
			<div className="header-filter-container">
				<div className="select-container">
					<h3>Compte</h3>
					<Select
						className='react-select'
						classNamePrefix="react-select"
						options={optionsAccounts}
						styles={getStylesSelectMenu()}
						value={optionsAccounts.filter( option => option.value === selectedInstance )}
						components={{
							IndicatorSeparator: () => null
						}}
						onChange={ payload => dispatch( updateSelectedInstance( payload.value ) ) }
					/>
				</div>
			</div>
		: false
	);
}

export default HeaderNavAccounts;