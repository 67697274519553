/** Dependencies **/
import React, {useEffect, useRef, useState} from 'react';
import ReactTooltip from 'react-tooltip';
import DataVizPie from '../DataViz/DataVizPie';

/** Redux **/
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedFilters } from './../../reducers/selectedFilters';
import { updateListCategoryDisp } from './../../reducers/listCategoryDisp';

/** Helpers **/
import { togglerClassName, truncateString, kFormatter } from './../../helpers/functions'
import { getPicto } from './../../helpers/pictos'

/** SCSS **/
import './TopNavCategory.scss';

function TopNavCategory() 
{
	/** Instance dispatch object **/
	const dispatch = useDispatch();

	/** Init state **/
	const [globalPotential, setGlobalPotential] = useState( 0 );
	const [globalPotentialPercent, setGlobalPotentialPercent] = useState( 100 );
	const [resetCategoriesFilter, setResetCategoriesFilter] = useState( false );
		
	/** Get state from redux store **/
	const filtersDatas = useSelector( state => state.filtersDatas.value );
	const listCategoryDisp = useSelector( state => state.listCategoryDisp.value );
	const instanceConfig = useSelector( state => state.config.value );  

	/** Init Refs **/
	const categoryButton = useRef(null);
	const togglerSelectAllCat1 = useRef(null);
	const togglerSelectAllCat2 = useRef(null);
	const togglerSelectAllCat3 = useRef(null);
	
	/** Init const **/
	const catDispLength = 25;
	
	/**
	 * Update selectedFilters with values of categories
	 * 
	 * @param {*} listCategories 
	 * {
			"category1": [
					{
							"value": "Activités nautiques",
							"totalkeywords": 155,
							"totalvolume": 152680
					},
					...
	 * @returns void(0)
	 */
	const setFilter = ( listCategories ) => 
	{	
		let selectedCat = [];
		
		if( listCategories !== null )
		{
			// get selected categories
			const selectedCategories = getSelectedCategories( listCategories );

			// filter selected categorie to get label
			const selectedCat1 = selectedCategories.category1.map( cat1 => cat1.label );
			const selectedCat2 = selectedCategories.category2.map( cat2 => cat2.label );
			const selectedCat3 = selectedCategories.category3.map( cat3 => cat3.label );

			// merge all selected Cats
			selectedCat = [...selectedCat1, ...selectedCat2, ...selectedCat3];

		} else {

			// reset toggler select all
			togglerSelectAllCategories( togglerSelectAllCat1.current, { status: true, value: true } );
			togglerSelectAllCategories( togglerSelectAllCat2.current, { status: true, value: true } );
			togglerSelectAllCategories( togglerSelectAllCat3.current, { status: true, value: true } );

			// reset categories 
			setResetCategoriesFilter( true );
		}
		
		// dispatch values to redux categoriesFilter
		dispatch( updateSelectedFilters( { filterName: 'categoriesFilter', value: selectedCat } ) );
	}
	
	/**
	 * Return object with essentials selected categories
	 * 
	 * @param {*} listCategories 
	 * * {
			"category1": [
					{
							"value": "Activités nautiques",
							"totalkeywords": 155,
							"totalvolume": 152680
					},
					...
	 *
	 * @returns void(0)
	 */
	const getSelectedCategories = ( listCategories ) => 
	{
		let result = {};

		// get category3 checked
		result.category3 = listCategories.category3.filter( cat3 => 
			cat3.checked === true 
		).map( cat3 => ( {label: cat3.value, totalkeywords:cat3.totalkeywords, totalvolume:cat3.totalvolume} ) );

		// get category2 checked
		result.category2 = listCategories.category2.filter( cat2 => {
			if( 
				cat2.checked === true 
				&& result.category3.filter( cat3 => cat3.label.startsWith( cat2.value ) ).length === 0 
			)
				return true;
			else
				return false;
		}).map( cat2 => ( {label: cat2.value, totalkeywords:cat2.totalkeywords, totalvolume:cat2.totalvolume} ) );

		// get category1 checked
		result.category1 = listCategories.category1.filter( cat1 => {
			if( 
				cat1.checked === true
				&& result.category3.filter( cat3 => cat3.label.startsWith( cat1.value ) ).length === 0 
				&& result.category2.filter( cat2 => cat2.label.startsWith( cat1.value ) ).length === 0 
			)
				return true;
			else
				return false;
		}).map( cat1 => ( {label: cat1.value, totalkeywords:cat1.totalkeywords, totalvolume:cat1.totalvolume} ) );

		return result;
	}

	
	/**
	 * Select/unselect all categories
	 * 
	 * @param {DOMElement} element input
	 * @param {object} forceCheck {status:false, value:false}
	 * @param {Array of DOMElement or DOMElement} except 
	 */
	const togglerSelectAllCategories = ( element, forceCheck={status:false, value:false}, except=null ) => 
	{
		// transform except to array if domelement
		let exceptions = [except];
		if( Array.isArray(except) )
			exceptions = except;

		// // get all checkbox which name nameCat
		let checkboxesNodeList = element.closest('div.category-container').querySelectorAll('ul li input');
		
		// // convert nodelist to array
		let checkboxes = Array.from( checkboxesNodeList );

		// // check or unchek all input
		if( checkboxes && checkboxes.length > 0 )
		{
			// get current check
			let currentCheck = element.checked;				
			if( forceCheck.status === true )
				currentCheck = forceCheck.value;

			const bangChecked = currentCheck === false ? 'checked' : false;

			// check all checkbox and get value
			checkboxes = checkboxes.map( checkbox => 
			{
				// set checked value on checkbox value
				!exceptions.includes( checkbox ) ? checkbox.checked = currentCheck : checkbox.checked = bangChecked;

				return checkbox;
			});
			
			// check categories with array of input checkboxes
			checkCategory( checkboxes );
		}
	}

	/**
	 * Check only value
	 * 
	 * @param {evt} e after click on only selected button 
	 */
	const checkOnly = ( e ) => 
	{
		// get associated input
		const input = e.currentTarget.parentNode.firstChild;

		// get allToggler
		const togglerSelectAll = input.closest('div.category-container').querySelector('div.title input');

		// unselect all categories from clicked category
		togglerSelectAllCategories( togglerSelectAll, {status:true, value:false}, input );
	}

	/**
	 * update subcat after check cat
	 * @param {DOMElement or array of DOMElement} element input
	 */
	const checkCategory = ( element ) => 
	{		
		// deep copy of listCategoryDisp
		let dispCat = JSON.parse( JSON.stringify( listCategoryDisp ) );

		// insert element into array if not
		let checkboxes = [element];
		if( Array.isArray(element) )
			checkboxes = element;

		// forEach input checkbox
		checkboxes.forEach( checkbox => 
		{
			// get value of category
			let catValue = '';
			if( checkbox.value && checkbox.value !== '' )
				catValue = checkbox.value;

			// get current check
			let currentCheck = checkbox.checked;

			// get tree of categories
			let splitValue = catValue.split( '|' );
			switch ( splitValue.length ) 
			{
				// click on category1
				case 1:

					// get index of selected category
					const indexCat1 = dispCat['category1'].findIndex( cat1 => cat1.value === catValue );

					// checked === true
					if( currentCheck === true )
					{
						/** Update value of checkboxes level 1 to true **/
						dispCat['category1'][indexCat1].checked = true;
						
						/** Update checkboxes cat 2 to add subcategories level 2 **/
						Object.keys( filtersDatas.categories[catValue].cat2 )
						.sort( ( a, b ) => a.localeCompare( b ) )
						.forEach( (cat2, index ) => 
						{
							const indexCat = dispCat['category2'].findIndex( cat => cat.value === catValue + '|' + cat2 );

							if( indexCat === -1 )
								dispCat['category2'] = [...dispCat['category2'], 
									{
										value: catValue + '|' + cat2, 
										checked: true, 
										index: index, 
										totalkeywords: filtersDatas.categories[catValue].cat2[cat2].totalkeywords,
										totalvolume: filtersDatas.categories[catValue].cat2[cat2].totalvolume
									}
								];
							else
								dispCat['category2'][indexCat].checked = true;

							/** Update checkboxes cat 3 to add subcategories level 3 **/
							Object.keys( filtersDatas.categories[catValue].cat2[cat2].cat3 )
							.sort( ( a, b ) => a.localeCompare( b ) )
							.forEach( ( cat3, index ) => 
							{
								const indexCat = dispCat['category3'].findIndex( cat => cat.value === catValue + '|' + cat2 + '|' + cat3 );

								if( indexCat === -1 )
									dispCat['category3'] = [...dispCat['category3'], 
										{
											value: catValue + '|' + cat2 + '|' + cat3, 
											checked: true, 
											index: index, 
											totalkeywords: filtersDatas.categories[catValue].cat2[cat2].cat3[cat3].totalkeywords,
											totalvolume: filtersDatas.categories[catValue].cat2[cat2].cat3[cat3].totalvolume
										}
									];
								else
									dispCat['category3'][indexCat].checked = true;
							});
						})

					// checked === false
					} else {

						/** Update value of checkboxes level 1 to false **/
						dispCat['category1'][indexCat1].checked = false;
						
						/** Remove all subcategories level 2 **/
						dispCat['category2'] = dispCat['category2'].filter( cat2 => !cat2.value.startsWith( catValue + '|' ) );

						/** Remove all subcategories level 3 **/
						dispCat['category3'] = dispCat['category3'].filter( cat3 => !cat3.value.startsWith( catValue + '|' ) );
					}
					break;
					
				// click on category2
				case 2:

					// get index of selected category
					let indexCat2 = dispCat['category2'].findIndex( cat2 => cat2.value === catValue );

					// checked === true
					if( currentCheck === true )
					{
						/** Update checkboxes cat2 on checked **/
						dispCat['category2'][indexCat2].checked = true;

						/** Update checkboxes cat 3 to add subcategories level 3 **/
						Object.keys( filtersDatas.categories[splitValue[0]].cat2[splitValue[1]].cat3 )
						.sort( ( a, b ) => a.localeCompare( b ) )
						.forEach( (cat3, index) => 
						{
							const indexCat = dispCat['category3'].findIndex( cat => cat.value === catValue + '|' + cat3 );

							if( indexCat === -1 )
								dispCat['category3'] = [...dispCat['category3'], {
									value: catValue + '|' + cat3, 
									checked: true, 
									index: index, 
									totalkeywords: filtersDatas.categories[splitValue[0]].cat2[splitValue[1]].cat3[cat3].totalkeywords,
									totalvolume: filtersDatas.categories[splitValue[0]].cat2[splitValue[1]].cat3[cat3].totalvolume
								}];
							else
								dispCat['category3'][indexCat].checked = true;
						});

					// checked === false
					} else {

						/** Update checkboxes cat 2 **/
						// update checked to false
						dispCat['category2'][indexCat2].checked = false;

						/** Remove checkboxes cat 3 **/
						dispCat['category3'] = dispCat['category3'].filter( cat3 => !cat3.value.startsWith( catValue + '|' ) );
					}

					/** Update checkboxes cat 1 **/
					dispCat = updateCheckedParentCat( 1, splitValue[0], dispCat );

					break;

				// click on category3
				case 3:

					/** Update checkboxes cat 3 **/
					// get index of value in dispCat['category3]
					const indexCat3 = dispCat['category3'].findIndex( cat3 => cat3.value === catValue );

					// checked === true
					if( currentCheck === true )	
						dispCat['category3'][indexCat3].checked = true;
					// checked === false
					else						
						dispCat['category3'][indexCat3].checked = false;
					
					/** Update checkboxes cat 2 **/
					dispCat = updateCheckedParentCat( 2, splitValue[0] + '|' + splitValue[1], dispCat );

					/** Update checkboxes cat 1 **/
					dispCat = updateCheckedParentCat( 1, splitValue[0], dispCat );

					break;
				default:
					break;
			}	
		});

		/** Remove category 3 with category 2 checked states **/
		dispCat['category3'] = removeCategoriesWithParentStates( dispCat['category2'], dispCat['category3'] );

		/** Remove category 2 with category 1 checked states **/
		dispCat['category2'] = removeCategoriesWithParentStates( dispCat['category1'], dispCat['category2'] );

		// update listCategoryDisp
		dispatch( updateListCategoryDisp( dispCat ) );
	}
	
	/**
	 * Update parents checkboxes with new state true / false / partial
	 * @param {int} catID 
	 * @param {string} catValue 
	 * @param {array} dispCat 
	 * @returns {array} dispCat with checkboxes update
	 */
	const updateCheckedParentCat = ( catID, catValue, dispCat ) => 
	{
		// get index 
		const indexCat = dispCat['category' + catID].findIndex( cat => cat.value === catValue );

		// update state of parent cat 1 to true / false / partial
		const catFamily = dispCat['category' + ( parseInt(catID) + 1 )].filter( cat => 
			cat.value.startsWith( catValue ) 
		);
		const catFamilyChecked = dispCat['category' + ( parseInt(catID) + 1 )].filter( cat => 
			cat.value.startsWith( catValue ) 
			&& cat.checked === true 
		);
		const catFamilyPartial = dispCat['category' + ( parseInt(catID) + 1 )].filter( cat => 
			cat.value.startsWith( catValue ) 
			&& cat.checked === 'partial'
		);

		if( catFamilyChecked.length === 0 && catFamilyPartial.length === 0 )
			dispCat['category' + catID][indexCat].checked = false;
		else if ( catFamily.length === catFamilyChecked.length )
			dispCat['category' + catID][indexCat].checked = true;
		else
			dispCat['category' + catID][indexCat].checked = 'partial';

		return dispCat;
	}

	/**
	 * 
	 * @param {Array} parentCats 
	 * [
			{
					"value": "Activités neige|Autres activités",
					"checked": true,
					"index": 0,
					"totalkeywords": 5,
					"totalvolume": 1220
			},...
		]
	 * @param {Array} childrenCats 
	 * [
			{
					"value": "Activités neige|Autres activités|N.C.",
					"checked": true,
					"index": 0,
					"totalkeywords": 5,
					"totalvolume": 1220
			},...
		]
	 * @returns {Array} childrensCats modified with checkboxes updated
	 */
	const removeCategoriesWithParentStates = ( parentCats, childrenCats ) => 
	{
		const catChecked = parentCats.filter( cat => cat.checked === false );
		catChecked.forEach( parentCat => 
		{
			childrenCats = childrenCats.filter( childCat => 
				!childCat.value.startsWith( parentCat.value + '|' ) 
			);
		});

		return childrenCats;
	}

	/** Update categories filter when filtersDatas.categories changed **/
	useEffect( () => 
	{
		if( filtersDatas.categories )
		{
			/** Update categories displayed **/
			let resetAllSelectedCat = { 'category1':[], 'category2':[], 'category3':[] };

			Object.keys( filtersDatas.categories )
			.sort( ( a, b ) => a.localeCompare( b ) )
			.forEach( (cat1, index) => 
			{
				resetAllSelectedCat.category1 = [...resetAllSelectedCat.category1, {
					value: cat1,
					checked: listCategoryDisp.category1.filter( cat => cat.value === cat1 ).length === 1 
						? listCategoryDisp.category1.filter( cat => cat.value === cat1 )[0].checked 
						: true,
					index: index,
					totalkeywords: filtersDatas.categories[cat1].totalkeywords,
					totalvolume: filtersDatas.categories[cat1].totalvolume
				}];

				Object.keys( filtersDatas.categories[cat1].cat2 )
				.sort( ( a, b ) => a.localeCompare( b ) )
				.forEach( (cat2, index) => 
				{
					if( 
						listCategoryDisp.category2.length === 0
						|| listCategoryDisp.category2.filter( cat => cat.value === cat1 + '|' + cat2 ).length === 1  
					)
						resetAllSelectedCat.category2 = [...resetAllSelectedCat.category2,  {
							value: cat1 + '|' + cat2,
							checked: listCategoryDisp.category2.filter( cat => cat.value === cat1 + '|' + cat2 ).length === 1
								? listCategoryDisp.category2.filter( cat => cat.value === cat1 + '|' + cat2 )[0].checked
								: true,
							index: index,
							totalkeywords: filtersDatas.categories[cat1].cat2[cat2].totalkeywords,
							totalvolume: filtersDatas.categories[cat1].cat2[cat2].totalvolume
						}];

					Object.keys( filtersDatas.categories[cat1].cat2[cat2].cat3 )
					.sort( ( a, b ) => a.localeCompare( b ) )
					.forEach( (cat3, index) => 
					{
						if( 
							listCategoryDisp.category3.length === 0
							|| listCategoryDisp.category3.filter( cat => cat.value === cat1 + '|' + cat2 + '|' + cat3 ).length === 1  
						)
							resetAllSelectedCat.category3 = [...resetAllSelectedCat.category3,  {
								value: cat1 + '|' + cat2 + '|' + cat3,
								checked: listCategoryDisp.category3.filter( cat => cat.value === cat1 + '|' + cat2 + '|' + cat3 ).length === 1
									? listCategoryDisp.category3.filter( cat => cat.value === cat1 + '|' + cat2 + '|' + cat3 )[0].checked
									: true,
								index: index,
								totalkeywords: filtersDatas.categories[cat1].cat2[cat2].cat3[cat3].totalkeywords,
								totalvolume: filtersDatas.categories[cat1].cat2[cat2].cat3[cat3].totalvolume
							}]
					});
				});
			});
			
			dispatch( updateListCategoryDisp( resetAllSelectedCat ) );

			/** Update global keywords end volumes for all categories **/
			// get selected categories
			const selectedCategories = Object.values( getSelectedCategories( resetAllSelectedCat ) ).reduce( ( a, b ) => b.concat( a ) );

			// get total volume
			const totalVolume = Object.values( filtersDatas.categories ).reduce( ( a, b ) => a + b.totalvolume, 0 );

			// get selected keywords
			const totalSelectedKeywords = Object.values( selectedCategories ).reduce( ( a, b ) => a + b.totalkeywords, 0 );
			
			// get selected volume			
			const totalSelectedVolume = selectedCategories.reduce( (a, b) => a + b.totalvolume, 0 );

			setGlobalPotential( totalSelectedKeywords );
			setGlobalPotentialPercent( parseFloat( ( totalSelectedVolume * 100 / totalVolume ).toFixed( 2 ) ) );
		}

		/** Reset resetCategoriesFilter **/
		setResetCategoriesFilter( false );

	}, [ filtersDatas.categories, resetCategoriesFilter ]);

	useEffect( () => 
	{
		if(
			listCategoryDisp 
			&& listCategoryDisp.category1.length > 0
		){
			/** Update global keywords end volumes for all categories **/
			// get selected categories
			const selectedCategories = Object.values( getSelectedCategories( listCategoryDisp ) ).reduce( ( a, b ) => b.concat( a ) );

			// get total volume
			const totalVolume = Object.values( listCategoryDisp.category1 ).reduce( ( a, b ) => a + b.totalvolume, 0 );

			// get selected keywords
			const totalSelectedKeywords = Object.values( selectedCategories ).reduce( ( a, b ) => a + b.totalkeywords, 0 );
			
			// get selected volume			
			const totalSelectedVolume = selectedCategories.reduce( (a, b) => a + b.totalvolume, 0 );

			setGlobalPotential( totalSelectedKeywords );
			setGlobalPotentialPercent( parseFloat( ( totalSelectedVolume * 100 / totalVolume ).toFixed( 2 ) ) );
		}

	}, [ listCategoryDisp ])

	return (
		<div className="filter-container category">
			<h4>filtrer les mots clés</h4>
			<button 
				ref={categoryButton}
				className="filter" 
				data-for={'filter-category'} 
				data-tip
				data-event='click'
				onClick={ e => !e.currentTarget.classList.contains('open') ? togglerClassName( e.currentTarget, 'open' ) : '' }				
			>
				<span className="title">categorie</span>
				{
					listCategoryDisp.category1.length > 0 
					?
						<span 
							className="value"
							title={ listCategoryDisp.category1.filter( cat => cat.checked === true || cat.checked === 'partial' ).map( cat =>cat.value ).join(' / ') }
						>{truncateString( listCategoryDisp.category1.filter( cat => cat.checked === true || cat.checked === 'partial' ).map( cat => cat.value ).join(' / '), 34, '...') }</span>
				 	: ''
				}
				<span className="arrow">{getPicto('KeyboardArrowDown', {size:"1.2rem", viewBox:"0 3 22 22"})}</span>
			</button>
			<ReactTooltip 
				id="filter-category"
				className="tooltip red"
				effect='solid'
				delayHide={100}
				delayShow={100}
				delayUpdate={500}
				globalEventOff="click"
				isCapture={true}
				arrowColor="transparent"
				overridePosition={({ left, top }, _currentEvent, currentTarget) => 
				{
					left = currentTarget.offsetLeft;
					top = currentTarget.offsetTop + currentTarget.offsetHeight;
					
					return { top, left };
				}}
				afterHide={ e => togglerClassName( categoryButton.current, 'open' ) }
			>
				<div className="category-container">
					<div className="title">
						<input 
							ref={togglerSelectAllCat1}			
							id="category-1" 
							name="category-1-main" 
							type="checkbox"
							checked={(
								listCategoryDisp
								&& listCategoryDisp.category1.filter( cat1 => cat1.checked === true ).length > 0
								&& listCategoryDisp.category1.filter( cat1 => cat1.checked === true ).length === listCategoryDisp.category1.length
							) ? 'checked' : false }
							className={(
								listCategoryDisp
								&& listCategoryDisp.category1.filter( cat1 => cat1.checked === false || cat1.checked === 'partial' ).length > 0
								&& listCategoryDisp.category1.filter( cat1 => cat1.checked === false ).length < listCategoryDisp.category1.length
							) ? 'partial' : '' }
							onChange={ e => togglerSelectAllCategories( e.currentTarget ) }
						/><label htmlFor="category-1">catégories</label>
					</div>
					<ul>
						{(
							listCategoryDisp 
							&& listCategoryDisp.category1.length > 0
						) ? 
							listCategoryDisp.category1.map( ( cat1, index ) => 
								<li key={index}>
									<input 
										id={"category-1-"+index} 
										name="category-1"
										type="checkbox" 
										value={cat1.value}
										className={cat1.checked === 'partial' ? cat1.checked : '' }
										checked={cat1.checked !== true ? false : true}
										onChange={ e => checkCategory( e.currentTarget ) }
									/>
									<label htmlFor={"category-1-"+index}>
										<span className="category-name" title={cat1.value}>{truncateString(cat1.value, catDispLength, '...')}</span>
										<span className="category-value">{kFormatter( cat1.totalvolume )}</span>
									</label>								
									<span className='category-only' onClick={e => checkOnly(e)}>seulement</span>
								</li>
							)
							: false 
						}
					</ul>
				</div>
				<div className="category-container">
					<div className="title">
						<input 
							ref={togglerSelectAllCat2}
							id="category-2" 
							name="category-2-main" 
							type="checkbox" 		
							checked={(
								listCategoryDisp
								&& listCategoryDisp.category2.filter( cat2 => cat2.checked === true ).length > 0
								&& listCategoryDisp.category2.filter( cat2 => cat2.checked === true ).length === listCategoryDisp.category2.length
							) ? 'checked' : false }
							className={(
								listCategoryDisp
								&& listCategoryDisp.category2.filter( cat2 => cat2.checked === false || cat2.checked === 'partial' ).length > 0
								&& listCategoryDisp.category2.filter( cat2 => cat2.checked === false ).length < listCategoryDisp.category2.length
							) ? 'partial' : '' }
							onChange={ e => togglerSelectAllCategories( e.currentTarget ) } 							
						/><label htmlFor="category-2">sous-catégories</label>
					</div>
					<ul>
						{(
							listCategoryDisp 
							&& listCategoryDisp.category2.length > 0
						) ? 
							[...listCategoryDisp.category2].sort( ( a, b ) => a.value.localeCompare( b.value ) ).map( (cat2, index) =>
								<React.Fragment key={index}>
									{ cat2.index === 0 
										? 
										<li className="title">{cat2.value.split('|')[0]}</li> 
										: false 
									}
									<li>										
										<input 
											id={"category-2-"+index} 
											name="category-2" 										
											type="checkbox" 
											value={cat2.value}
											className={cat2.checked === 'partial' ? cat2.checked : '' }
											checked={cat2.checked !== true ? false : true}
											onChange={ e => checkCategory( e.currentTarget ) }
										/>
										<label htmlFor={"category-2-"+index}>
											<span className="category-name" title={cat2.value.split('|')[1]}>{truncateString(cat2.value.split('|')[1], catDispLength, '...')}</span>
											<span className="category-value">{kFormatter( cat2.totalvolume )}</span>
										</label>								
										<span className='category-only' onClick={e => checkOnly(e)}>seulement</span>
									</li>
								</React.Fragment>
							)
							: false
						}
					</ul>
				</div>
				<div className="category-container">
					<div className="title">
						<input 
							ref={togglerSelectAllCat3}
							id="category-3" 
							name="category-3-main" 
							type="checkbox"
							checked={(
								listCategoryDisp
								&& listCategoryDisp.category3.filter( cat3 => cat3.checked === true ).length > 0
								&& listCategoryDisp.category3.filter( cat3 => cat3.checked === true ).length === listCategoryDisp.category3.length
							) ? 'checked' : false }
							className={(
								listCategoryDisp
								&& listCategoryDisp.category3.filter( cat3 => cat3.checked === false || cat3.checked === 'partial' ).length > 0
								&& listCategoryDisp.category3.filter( cat3 => cat3.checked === false ).length < listCategoryDisp.category3.length
							) ? 'partial' : '' }	
							onChange={ e => togglerSelectAllCategories( e.currentTarget ) }
						/><label htmlFor="category-3">sous-sous-catégories</label>
					</div>
					<ul>
						{(
							listCategoryDisp 
							&& listCategoryDisp.category3.length > 0
						) ? 
							[...listCategoryDisp.category3].sort( ( a, b ) => a.value.localeCompare( b.value ) ).map( (cat3, index) => 
								<React.Fragment key={index}>
									{ cat3.index === 0 
										?
										<li className="title">
											<span className="subtitle" title={cat3.value.split('|')[0]+' / '+cat3.value.split('|')[1]}>{truncateString(cat3.value.split('|')[0], 14, '...')}&nbsp;/&nbsp;</span>{truncateString(cat3.value.split('|')[1], 14, '...')}
										</li>
										: false
									}
									<li>
										<input 
											id={"category-3-"+index} 
											name="category-3" 
											type="checkbox" 
											value={cat3.value}
											checked={cat3.checked}
											onChange={ e => checkCategory( e.currentTarget ) }
										/>
										<label htmlFor={"category-3-"+index}>
											<span className="category-name" title={cat3.value.split('|')[2]}>{truncateString(cat3.value.split('|')[2], catDispLength, '...')}</span>
											<span className="category-value">{kFormatter( cat3.totalvolume )}</span>
										</label>								
										<span className='category-only' onClick={e => checkOnly(e)}>seulement</span>
									</li>
								</React.Fragment>
							)
							: false
						}
					</ul>
				</div>
				{
				<div className="global-potential">
					<DataVizPie 
						datas={[{x: "potential", y: globalPotentialPercent},{x: "empty", y: 100 - globalPotentialPercent}]}
					/>
					<p><span>{globalPotential}</span><br/>mots clés<br/>soit<br/><span>{globalPotentialPercent}%</span><br/>du potentiel global</p>
					<div className="confirm-container">
						<button className="reset" onClick={ e => setFilter( null )}>annuler</button>
						<button className="confirm" onClick={ e => setFilter( listCategoryDisp )}>APPLIQUER</button>
					</div>
				</div>
				}
			</ReactTooltip>
		</div>
	);
}

export default TopNavCategory;