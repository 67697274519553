/** Dependencies **/
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

/** Helpers **/
import { 
  togglerClassName
} from './../functions';

/**
 * Rewrite datas of Object Trend to use it in Data Viz chart
 * @param {Object} data {
    "impressions": [
        {
            "x": 202103,
            "y": "470882"
        }...
    ],
    "clicks": [
        {
            "x": 202103,
            "y": "6091"
        }...
    ],
    "ctr": [
        {
            "x": 202103,
            "y": "0.0129"
        }...
    ],
    "position": [
        {
            "x": 202103,
            "y": 25.4844079643237
        },...
    ]
}
 * @returns Array format for data viz
 */
export const rewriteTrendObjectData = ( data, periodType = 'month' ) => 
{
  let results = {};

  Object.keys( data ).forEach( key => 
  {
    results = {...results, [key]: data[key].map( (element, index) => 
    {
      return {
        x: index + 1,
        y: parseFloat( element.y ),
        date: element.x,
        tickValue: formatTickValue( element.x, periodType ),
        labelValue: key
      }
    })};
  });

  return results;
}

/**
 * Rewrite datas of Array Trend to use it in Data Viz chart
 * @param {Object} data [
    {
        "x": "202104",
        "y": "2123270"
    },
    ...
]
}
 * @returns Array format for data viz
 */
export const rewriteTrendArrayData = ( data, period ) => 
{
  let results = [];

  data.forEach( (element, index) => 
  {
    results = [...results, {
      x: index + 1,
      y: parseInt( element.y ),
      tickValue: new Date( 
        element.x.toString().substring( 0, 4 ), 
        element.x.toString().substring( 4, 6 ) - 1 
      ).toLocaleString('fr-FR', { month: 'short' } ).toUpperCase() + ' ' + element.x.toString().substring( 2, 4 ),
      labelValue: period
    }]
  });

  return results;
}

/**
 * Rewrite datas of Array Details to use it in Data Viz chart
 * @param {Object} data [
    {
        "typeSnippet": "Featured Snippet",
        "percent": 1.31
    },
    {
        "typeSnippet": "PAA",
        "percent": 1.61
    },
    ...
]
}
 * @returns Array format for data viz
 */
export const rewriteDetailsArrayData = ( data ) => 
{
  let results = [];

  data.forEach( (element, index) => 
  {
    results = [...results, {
      x: element.typeSnippet,
      y: parseFloat( element.percent ),
      tickValue: element.typeSnippet
    }]
  });

  return results;
}

/**
 * Rewrite datas of Array Details to use it in Data Viz chart
 * @param {Object} data [
    {
        "typeSnippet": "Featured Snippet",
        "percent": 1.31
    },
    {
        "typeSnippet": "PAA",
        "percent": 1.61
    },
    ...
]
}
 * @returns Array format for data viz
 */
export const rewriteDetailsObjectData = ( data ) => 
{
  let results = [];

  Object.keys( data ).forEach( element => 
  {
    results = [...results, {
      x: element,
      y: parseFloat( data[element].percent ),
      tickValue: element
    }]
  });

  return results;
}

 /**
  * Round value with 1 decimal if range 0 < 1
  * @param {float} value 
  * @returns float
  */
 export const getRoundValueLabel = ( value ) => 
 {
  const yRound = Math.round( ( value + Number.EPSILON ) * 10 ) / 10;
  let result = null;
                    
  if(
    yRound > 0 
    && yRound <= 1
  )
    result = new Intl.NumberFormat( 'fr-FR', {maximumFractionDigits: 1} ).format( yRound ) + '%';
  else if( yRound > 1 )
    result = new Intl.NumberFormat( 'fr-FR', {maximumFractionDigits: 1} ).format( Math.round( value ) ) + '%';
  else
    result = '' ;

  return result;
 }

 /**
 * Download globale trend to PDF file
 * @param {DOMElement} element 
 */
export const exportGlobalTrend2PDF = ( element ) => 
{
  // display global loader
  togglerClassName( document.getElementById( 'loader-global' ), 'hide' );

  var timeout = setTimeout( 
    () => 
    {
      html2canvas( element.current )
      .then( canvas => 
      {
        // set png format
        const imgData = canvas.toDataURL( 'image/png' );
        
        // instance jsPDF with options format
        const pdf = new jsPDF( 'l', 'mm', 'a4' );

        // attach image to PDF file
        pdf.addImage( imgData, 'PNG', 0, 70, 297, 0 );

        // download globalTrend.pdf file
        pdf.save( "globalTrend.pdf" );  

        // hide global loader
        togglerClassName( document.getElementById( 'loader-global' ), 'hide' );
      });

      // clear timeout
      clearTimeout( timeout );
    }, 
    100
  );
}

/**
 * Format tick value with period type => month or week or...
 * @param {string} date 
 * @param {string} periodType 
 * @returns string
 */
export const formatTickValue = ( date, periodType ) => 
{
  let result = null;

  switch ( periodType ) {
    case 'week':
      result = date.toString().substring( 6, 8 ) + '/' + date.toString().substring( 4, 6 ) + '/' + date.toString().substring( 2, 4 )
      break;
  
    default:
      result = new Date( 
        date.toString().substring( 0, 4 ), 
        date.toString().substring( 4, 6 ) - 1 
      ).toLocaleString('fr-FR', { month: 'short' } ).toUpperCase() + ' ' + date.toString().substring( 2, 4 )
      break;
  }

  return result;
}