/** Dependencies **/
import React, {useState, useRef, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';

/** Redux **/
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedFilters } from './../../reducers/selectedFilters';

/** Helpers **/
import { togglerClassName } from './../../helpers/functions';
import { getPicto } from './../../helpers/pictos'

/** SCSS **/
import './TopNavDevLoc.scss';

function TopNavDevLoc() 
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const instanceConfig = useSelector( state => state.config.value );  

  /** Init state **/
  const [options, setOptions] = useState( null );
  const [devLoc, setDevLoc] = useState( null );
  const [countryLabel, setCountryLabel] = useState( null );
  const [cityDeviceLabel, setCityDeviceLabel] = useState( null );

  /** Init Refs **/
	const contextButton = useRef( null );

  /** Update selectedFilters with values of categories **/
	const setFilter = ( value ) => 
	{	
    const devLoc = getDevLoc( value );

    setDevLoc( devLoc );
    setCountryLabel( value.countryLabel );
    setCityDeviceLabel( value.cityLabel + ' / ' + value.deviceLabel );
		
		// dispatch values to redux devLoc
		dispatch( updateSelectedFilters( { filterName: 'devLoc', value: value } ) );
	}

  /**
   * Return country|device|location from object {countryValue:..., countryLabel:...}
   * @param {Object} value 
   * @returns String
   */
  const getDevLoc = ( value ) => 
  {
    let result = null;

    if( value.countryValue && value.cityValue && value.deviceValue )
      result = value.countryValue + '|' + value.cityValue + '|' + value.deviceValue;

    return result;
  }

  // Init Device Menu
  useEffect( () => 
  {
    if( 
      options instanceof Array 
      && options[0] 
    ){
      setDevLoc( getDevLoc( options[0] ) );
      setCountryLabel( options[0].countryLabel );
      setCityDeviceLabel( options[0].cityLabel + ' / ' + options[0].deviceLabel );

      // dispatch values to redux devLoc
		  dispatch( updateSelectedFilters( { filterName: 'devLoc', value: options[0] } ) );
    }

  }, [ options ] )

  /** Get config values **/
  useEffect( () => 
  {
    if( instanceConfig !== null )
      setOptions( instanceConfig.devLoc );

  }, [ instanceConfig ] );
		
	return (
		<React.Fragment>
			<div className="filter-container context">
        <h4>afficher</h4>
        <button 
          ref={contextButton}
          className={ options instanceof Array && options.length > 1 ? 'filter' : 'filter no-options' } 
          data-for={'filter-devloc'} 
          data-tip
          data-event='click'
          onClick={ e => options instanceof Array && options.length > 1 ?
            !e.currentTarget.classList.contains( 'open' ) ? 
              togglerClassName( e.currentTarget, 'open' ) 
              : ''
            : false
          }
        >
          <span className="title">GOOGLE {countryLabel}</span>
          <span className="value">Localisé à {cityDeviceLabel}</span>
          <span className="arrow">{ options instanceof Array && options.length > 1 ? 
            getPicto( 'KeyboardArrowDown', { size: "1.2rem", viewBox: "0 3 22 22" } ) 
            : false 
          }</span>
        </button>
        <ReactTooltip 
          id={'filter-devloc'}
          className="tooltip red"
          effect='solid'
          delayHide={100}
          delayShow={100}
          delayUpdate={500}
          globalEventOff='click'
          disable={ options instanceof Array && options.length > 1 ? false : true }
          isCapture={true}
          arrowColor="transparent"
          overridePosition={( { left, top }, _currentEvent, currentTarget ) => 
          {
            left = currentTarget.offsetLeft;
            top = currentTarget.offsetTop + currentTarget.offsetHeight;
            
            return { top, left };
          }}
          afterHide={ e => togglerClassName( contextButton.current, 'open' ) }
        >
          <div className="filter">
            <div className="title">
              <h4>Veuillez choisir la configuration à considérer pour les résultats de recherche (SERP)</h4>
            </div>
            <hr className='separator' />
            <ul className="preselect">
              {
                options instanceof Array ?
                  options.map(( option, index ) => 
                    <li key={index}>
                      <button 
                        className={ ( 
                            devLoc !== null 
                            && devLoc === option.countryValue + '|' + option.cityValue + '|' + option.deviceValue 
                          )
                          ? 'on' 
                            : '' 
                        }
                        onClick={ e => setFilter( option || undefined ) }
                        value={ option.countryValue + '|' + option.cityValue + '|' + option.deviceValue }
                      >{ option.countryLabel + ' / ' + option.cityLabel + ' / ' + option.deviceLabel }</button>
                    </li>
                  )
                : false
              }
            </ul>
          </div>
        </ReactTooltip>
      </div>
		</React.Fragment>
	);
}

export default TopNavDevLoc;