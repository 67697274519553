/** Dependencies **/
import moment from 'moment';

/** Components **/
import { onToggler, offToggler } from './../reducers/toggler';

/**
 * Dispatch toggler value for togglerID
 * 
 * @param {object} togglerStore 
 * @param {string} togglerID 
 * @param {dispatch object} dispatch 
 */
export const handleClickToggler = ( togglerStore, togglerID, dispatch, forceToggler=undefined ) => 
{	
  if( (togglerStore[togglerID] !== undefined && togglerStore[togglerID] === true && forceToggler === undefined) || forceToggler === true )
    dispatch( offToggler( togglerID ) );
  else
    dispatch( onToggler( togglerID ) );
}

/**
 * 
 * @returns Object with common styles for select menu
 */
export const getStylesSelectMenu = () => {
  
  const styles = {
    control: (styles, state) => ({
      ...styles,
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0
      },
			cursor: 'pointer'
    }),
		option: (styles, state) => ({
			...styles,
			cursor: 'pointer'
		})
  };

  return styles;
}

/**
 * Return array with 24 last month to build select custom menu
 * @returns array
 */
export const getCustomDateOptionsList = ( 
  nbMonth, 
  endMonthDay = false, 
  valueFormat = 'YYYYMMDD', 
  labelFormat= 'MMM Y' 
) =>
{
  let list = [];

  for( let i = 0; i <= nbMonth; i++ )
  {
    // get month
    let option = endMonthDay === true ?
      moment().subtract( i, 'month').endOf( 'month' )
      : moment().subtract( i, 'month').startOf( 'month' );

    // push value to array
    list.push( { label: option.format( labelFormat ).toUpperCase(), value: option.format( valueFormat ) } );
  }

  return list;
}