import React from 'react';

const Checkbox = React.forwardRef( ({ indeterminate, id, ...rest }, ref) => 
{
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <React.Fragment>
        <input className="action-checkbox" id={"keyword-"+id} type="checkbox" ref={resolvedRef} {...rest} />
      </React.Fragment>
    )
  }
)

export default Checkbox;